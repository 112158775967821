import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout/layout";
import "./condiciones-legales.scss";
import { Link } from "gatsby";

export default function CondicionesLegales() {
    return (
      <React.Fragment>
        <SEO title="Condiciones legales" />
        <Layout pageId="privacy-policy">
            <div className="privacy-policy-container">
            <h1>En construcción</h1>
            </div>
        </Layout>
      </React.Fragment>
    )
    /* return (
      <React.Fragment>
        <SEO title="Condiciones legales" />
        <Layout pageId="privacy-policy">
          <div className="privacy-policy-container">
            <h1>
              Condiciones generales de uso y de venta de
              negligenciasdeabogados.net
            </h1>
            <h4>1.- TERMINOS Y CONDICIONES</h4>
            <p>
              Este sitio web,{" "}
              <Link to="www.negligenciasdeabogados.net">
                <strong>www.negligenciasdeabogados.net</strong>
              </Link>{" "}
              (en adelante &quot;el SITIO&quot;), proporciona a los usuarios un
              servicio gratuito de autovaloración sobre la posible viabilidad de
              reclamaciones contra abogados por negligencias profesionales
              cometidas por éstos en defensa de los intereses de sus clientes.
              El SITIO, también como servicio opcional, ofrece la puesta en
              contacto de los usuarios con expertos en responsabilidad civil
              profesional de abogados (en adelante los expertos) para consulta
              telefónica y/o la emisión de un dictamen jurídico sobre el caso
              del usuario. Los servicios del SITIO, que solo se prestan a
              residentes en territorio español y respecto de errores o
              negligencias de abogados españoles cometidas en España, se
              denominarán en adelante colectivamente &quot;SERVICIOS&quot;. Las
              siguientes disposiciones definen los derechos y obligaciones
              contractuales del operador del SITIO (negligenciasdebogados.net),
              y de los USUARIOS (tal y como se definen a continuación) y del
              SITIO. Se entenderá por USUARIO o USUARIOS a todas las personas
              que accedan al SITIO, ya sea navegando, leyendo, viendo,
              utilizando y/o comprando los servicios ofrecidos en el SITIO. La
              página de inicio del SITIO se encuentra en la siguiente URL:
              www.negligenciasdeabogados.net. El uso de los SERVICIOS de la WEB
              constituye la aceptación plena de estos Términos y Condiciones.
            </p>
            <p>
              En caso de duda sobre cualquiera de estos Términos y Condiciones,
              el USUARIO reconoce que se aplica la Legislación sobre Consumo y
              comercio electrónico vigentes en España. negligenciasdebogados.net
              y la página web www.negligenciasdeabogados.net no ofrecen ningún
              tipo de asesoramiento jurídico sino solo información general sobre
              reclamaciones a abogados.
            </p>
            <p>
              El contenido de las opiniones jurídicas emitidas por los expertos,
              bien telefónicamente o por mensajería instantánea (Te llamamos 30)
              bien a través de la emisión del dictamen, se atribuye
              exclusivamente a éstos no siendo el resultado de ningún trabajo de
              redacción, edición o modificación por parte de
              negligenciasdebogados.net o cualquier otra persona.
            </p>
            <h4>2.- ACCESO, NAVEGACIÓN Y CONTACTO</h4>
            <p>
              Cualquier acceso, uso, suscripción y/o compra en el SITIO WEB está
              disponible sólo para entidades legales y personas físicas mayores
              de edad y con capacidad de obrar.
            </p>
            <p>
              negligenciasdebogados.net puede, en cualquier momento, a su única
              y exclusiva discreción, suspender, limitar o interrumpir el SITIO
              WEB para actualizarlo o cambiar su contenido.
            </p>
            <p>
              El USUARIO se compromete a utilizar el SITIO WEB exclusivamente
              para su uso personal y, en cualquier caso, a no utilizar el SITIO
              WEB, los SERVICIOS o el contenido del SITIO WEB con fines
              ilegales, comerciales, publicitarios o de spam.
            </p>
            <p>
              Para cualquier consulta o información sobre los productos
              presentados en el SITIO o en el propio SITIO, o para denunciar
              contenidos o actividades ilegales, el USUARIO podrá enviar un
              correo electrónico a: administracion@negligenciasdeabogados.net, o
              bien enviar una carta certificada con acuse de recibo a:
              negligenciasdeabogados.net - Servicio de Atención al Cliente
              Negligencias de Letrados, C/ XXXXXXXX (dirección de la SLU) o
              enviar un mensaje a través del formulario de contacto, siendo este
              el medio de contacto preferente.
            </p>
            <h4> 3.- RESPONSABILIDAD</h4>
            <p>
              La única y exclusiva responsabilidad de negligenciasdeabogados.net
              será en el contenido específico proporcione al USUARIO a través
              del SITIO y de los SERVICIOS GRATUITOS. Cualquier información
              inexacta, incompleta o incorrecta añadida por el USUARIO o el uso
              incorrecto del SITIO y de los SERVICIOS GRATUITOS (incluyendo,
              pero no limitándose, al uso incorrecto del formulario gratuito
              accesible en el SITIO) será responsabilidad exclusiva del USUARIO,
              sin responsabilidad alguna por parte de
              negligenciasdeabogados.net.
            </p>
            <p>
              {" "}
              negligenciasdebogados.net no será responsable en los siguientes
              casos:
            </p>
            <ul>
              <li>
                Fallo técnico o informático. La incompatibilidad del SITIO con
                cualquier hardware o software no implica ninguna responsabilidad
                para negligenciasdeabogados.net
              </li>
              <li>
                Daños directos o indirectos, materiales o consecuentes,
                previsibles o imprevisibles al USUARIO, derivados del uso o la
                dificultad de uso del SITIO o de sus SERVICIOS.
              </li>
              <li>
                La circulación de cualquier información inexacta, no segura o no
                fiable en Internet. - La utilización ilegal del SITIO, sin que
                negligenciasdeabogados.net haya tenido conocimiento de ello.
              </li>{" "}
            </ul>
            <p>
              negligenciasdebogados.net no garantiza la eficacia, la integridad,
              la exactitud, el resultado y/o la efectividad de las conclusiones
              a que llegue el USUARIO mediante la inserción de respuestas en el
              formulario gratuito.
            </p>
            <p>
              negligenciasdebogados.net no se hace responsable de los errores,
              omisiones o inexactitudes del formulario gratuito con o sin
              cumplimentación ni de los daños y perjuicios que pueda sufrir el
              USUARIO que proceda a rellenarlo y finalizarlo en el sitio web.
            </p>
            <p> El USUARIO es responsable de:</p>
            <ul>
              <li>Proteger su equipo y sus datos</li>
              <li>El uso que se haga del SITIO o de los SERVICIOS</li>
              <li>Cualquier incumplimiento de estos Términos y Condiciones.</li>
            </ul>
            <p>
              El USUARIO reconoce expresamente que es de su entera
              responsabilidad el uso y resultado del formulario gratuito
              utilizando el servicio del SITIO.{" "}
            </p>
            <p>
              El USUARIO reconoce que el SITIO no ofrece ningún tipo de
              asesoramiento legal y que no es un bufete de abogados, por lo que
              el uso del SITIO y de las informaciones en él contenidas (blog y
              casos reales) no puede ser considerado como una opinión legal o
              sustituirla.
            </p>
            <p>
              {" "}
              Por lo tanto, para cualquier asunto legal relacionado con su caso,
              el USUARIO deberá contactar a profesionales del derecho
              competentes para llevar a cabo una consultoría legal.
            </p>
            <h4> 4.- PROPIEDAD INTELECTUAL</h4>
            <p>
              El conjunto de los elementos del SITIO (el dominio
              negligenciasdeabogados.net, la ergonomía, los textos, los
              gráficos, las imágenes, los sonidos, los vídeos, los elementos de
              programación, la base de datos - esta lista no se considerará
              exhaustiva-), en su conjunto o por separado, son propiedad
              exclusiva de negligenciasdeabogados.net.
            </p>
            <p>
              {" "}
              El acceso al SITIO no representa el reconocimiento de un derecho,
              ni confiere ningún derecho, incluidos los derechos de propiedad
              intelectual relativos a cualquiera de los elementos del SITIO, que
              siguen siendo propiedad exclusiva de negligenciasdebogados.net.
            </p>
            <p>
              Cualquier representación, reproducción, adaptación o explotación
              parcial o total de los contenidos, marcas y servicios prestados
              por el SITIO por cualquier medio, sin el consentimiento previo,
              expreso y escrito de negligenciasdebogados.net, está estrictamente
              prohibido.
            </p>
            <p>
              Se prohíbe al USUARIO introducir en el SITIO datos que puedan
              alterar su contenido o su aspecto.
            </p>
            <h4>
              5.- PRECIO Y CONDICIONES DE DEVOLUCION DE LOS SERVICIOS PREMIUMS O
              DE PAGO.
            </h4>
            <p>
              <strong>
                A) Te llamamos 30. Consulta telefónica por voz o por mensajería
                instantánea (Sin ánimo de exhaustividad: Whatsapp, Telegram,
                Signal, etc…)
              </strong>
            </p>
            <p>
              {" "}
              La página web proporciona al USUARIO la opción de un asesoramiento
              sobre su caso proporcionado por un experto durante 30 minutos por
              un precio de 30 € (IVA no incluido) en el horario y día que por el
              experto se le proponga a través de canales de comunicación
              mensajería instantánea (preferentemente whatsapp por su alta
              difusión), dentro de las 24 horas después del pago o, de no
              convenir al usuario, la que pacten ambos en las 48 ó 72 horas
              siguientes.
            </p>
            <p>
              negligenciasdebogados.net se reserva el derecho de cambiar los
              precios en cualquier momento publicándolos en línea.
            </p>
            <p>
              Los únicos precios aplicables a las transacciones de los USUARIOS
              son los vigentes en el momento de la compra.
            </p>
            <p>
              Los precios no incluyen el IVA aplicable (21%) en la fecha de la
              compra, no obstante, cualquier cambio en la tasa de estos
              impuestos se reflejará en la página web.
            </p>
            <p>
              {" "}
              El importe total de la compra, incluidos todos los impuestos, se
              indica antes de la confirmación final del pedido.
            </p>
            <p>
              El pago del precio total de la consulta debe hacerse en el momento
              de la compra.
            </p>
            <p>
              {" "}
              En el plazo máximo de 5 días se devolverá al USUARIO el importe o
              parte del importe del precio de los servicios según las siguientes
              condiciones:
              <ul>
                <li>
                  En aquellos casos en que por fuerza mayor o imposibilidad
                  personal grave el experto se vea impedido para contactar con
                  el USUARIO en la hora y fecha convenida, se procederá a la
                  devolución total del precio. No obstante, si contactado el
                  USUARIO con posterioridad - máximo 24 horas- presta su
                  conformidad a que se le preste el servicio en ese momento, no
                  procederá la devolución sino que el precio pagado se aplicará
                  a ese servicio de fecha y hora posterior.
                </li>
                <li>
                  En aquellos casos en que el USUARIO desee anular el servicio y
                  para que proceda la devolución que a continuación se
                  establece, deberá comunicarlo al experto como máximo dentro de
                  las 6 horas siguientes a la confirmación por parte de éste de
                  la hora acordada para prestar el servicio, en cuyo caso se le
                  devolverá el importe pagado descontándose 10 euros (más
                  IVA) en concepto de tramitación y gastos, siempre y cuando el
                  USUARIO lo solicite en plazo de 5 días a través del formulario
                  de contacto de la web conforme a las instrucciones que le
                  indique el experto, no procediendo devolución alguna para el
                  caso de no cumplirse con los plazos establecidos ambos
                  requisitos (6 horas para la anulación y 5 días para la
                  solicitud de devolución).
                </li>
                <li>
                  Si el USUARIO no avisare con la antelación suficiente o no
                  puediere atender al experto en el margen de 10 minutos
                  contados desde la hora del día acordado, aunque éste ya no
                  estará disponible para prestar el servicio, informará al
                  USUARIO tenerlo por resuelto con las instrucciones para que,
                  en su caso, solicite la devolución del importe que ha pagado
                  descontados 15 euros (más IVA) en concepto de tramitación y
                  gastos.  Para el caso de que el USUARIO no cumplimente la
                  solicitud de la devolución dentro de los 5 días siguientes no
                  procederá devolución alguna.
                </li>
              </ul>
            </p>
            <p>
              {" "}
              Por motivos de calidad del servicio respecto de la consulta
              telefónica, incluida la que se presta en el servicio de dictamen,
              y a los efectos del cumplimiento y perfección del contrato en el
              sentido anteriormente indicado, el experto requerirá al USUARIO su
              consentimiento, a los efectos probatorios y de calidad del
              servicio oportunos, para grabar la conversación y a requerimiento
              de negligenciasdebogados.net se pondrá a su disposición el
              contenido de ésta así como de las conversaciones mantenidas con el
              USUARIO por mensajería instantánea para el supuesto de que exista
              conflicto, divergencia o reclamación por parte del usuario.
            </p>
            <p>
              <strong>B) Servicio de dictamen.</strong>
            </p>
            <p>
              {" "}
              La página web también proporciona a los USUARIOS la opción de
              contratar los servicios profesionales de un experto a los efectos
              de redactar un dictamen jurídico sobre su caso a un precio de 170€
              (IVA no incluido), de los cuales 30 € (IVA no incluido) serán
              descontados del precio final a pagar al deber ser abonados
              inicialmente para su aplicación al servicio de asesoramiento
              telefónico previo, aplicándose a este asesoramiento las mismas
              condiciones que las establecidas en el servicio Te llamamos 30.
            </p>
            <p>
              {" "}
              El precio del servicio que es de 170€ (IVA no incluido) se pagará
              de la siguiente manera: una parte del precio en el momento del
              contrato del servicio (30€ IVA no incluido) y el resto, 140 € (IVA
              no incluido), antes de la descarga del documento final cuando se
              ponga a disposición del USUARIO.
            </p>
            <p>
              {" "}
              La prestación de este servicio de emisión de dictamen se divide en
              dos momentos: el del asesoramiento telefónico previo y el
              posterior de envío para su descarga del documento.
            </p>
            <p>
              {" "}
              Se aplicarán las condiciones expuestas en los párrafos anteriores
              del anterior apartado A) respecto del asesoramiento telefónico
              previo.
            </p>
            <p>
              {" "}
              No procederá devolución del inicial importe abonado de 30€ más IVA
              si desiste el USUARIO con posterioridad a la prestación efectiva
              del servicio de asesoramiento telefónico quedando el experto
              liberado de la redacción del dictamen. Si el USUARIO cambiase de
              opinión, podrá contratar de nuevo el dictamen, siendo necesario
              abonar nuevamente el importe del asesoramiento telefónico por los
              eventuales cambios de circunstancias a las valoradas en el primer
              asesoramiento.
            </p>
            <p>
              Una vez que el USUARIO pague el servicio de dictamen, el experto
              concertará cita telefónica en las mismas condiciones establecidas
              que en el servicio Te Llamamos 30. Una vez finalizado el
              asesoramiento, se remitirá al USUARIO un correo electrónico en el
              plazo de 24/48 horas un correo electrónico en el que se le
              recordará la documentación/información indicada telefónicamente
              por el experto necesaria para la emisión del dictamen, el cual
              será puesto a su disposición en el plazo máximo de 3 días desde
              que se reciba la documentación, debiendo abonar en ese momento el
              precio restante (140€ más IVA) para poder efectuar la descarga del
              documento que irá firmado con certificado digital por el experto.
            </p>
            <p>
              En el supuesto de que el Usuario anule el asesoramiento telefónico
              previo o no pueda prestársele ese servicio se considera resuelto
              el servicio de dictamen, con independencia de la devolución de los
              importes abonados que correspondan conforme a las mismas
              condiciones establecidas para el servicio Te llamamos 30.
            </p>
            <h4> 6.- PAGO </h4>
            <p>
              Cuando el USUARIO elige comprar la llamada, el pago se efectúa en
              el momento de la confirmación final de la compra.
            </p>
            <p>
              {" "}
              Respecto del dictamen se efectuará cuando la web le confirme que
              ya está redactado y disponible el mismo.
            </p>
            <p>
              {" "}
              Una vez recibida la confirmación de la compra y el pago por parte
              del USUARIO, éste recibirá una factura electrónica a la dirección
              de correo electrónico que haya especificado en la web tras la
              prestación del servicio que corresponda o por los importes que
              correspondan en los supuestos de anulación o desestimiento del
              servicio en concepto de gastos de gestión y tramitación.
            </p>
            <p>
              {" "}
              El USUARIO garantiza a negligenciasdebogados.net que tiene los
              permisos necesarios para utilizar el método de pago elegido y
              reconoce que la transmisión a negligenciasdeabogados.net de la
              información bancaria del USUARIO en prueba de su capacidad y
              consentimiento para la venta, y para el pago debido en virtud de
              la compra.
            </p>
            <p>
              {" "}
              negligenciasdebogados.net no guarda los detalles de las tarjetas
              de crédito del USUARIO, pero la prueba de la transacción se
              archiva.
            </p>
            <p>
              {" "}
              Si los organismos acreditados se niegan a autorizar el pago con
              tarjeta de crédito o en caso de impago, negligenciasdeabogados.net
              se reserva el derecho de suspender o cancelar la compra y su
              entrega.
            </p>
            <p>
              {" "}
              negligenciasdebogados.net también se reserva el derecho de
              rechazar una compra realizada por un USUARIO que no haya pagado
              completamente su compra o no haya pagado completamente una compra
              anterior o con el que no se haya resuelto un litigio.
            </p>
            <p>
              {" "}
              negligenciasdebogados.net no se hace responsable de los problemas
              de pago relacionados con el funcionamiento de los SERVICIOS. El
              USUARIO reconoce ser el único responsable de cualquier problema
              relacionado con el método de pago elegido para acceder a los
              SERVICIOS.
            </p>
            <h4> 7.- ASESORAMIENTO JURÍDICO</h4>
            <p>
              {" "}
              Esta disposición sólo se aplicará a los USUARIOS residentes en
              España pues los servicios específicos (llamada y dictamen) de
              comunicación con un experto no están disponible en ningún otro
              País.
            </p>
            <p>
              {" "}
              El SITIO puede ofrecer al USUARIO la posibilidad de hablar con un
              experto y/o ponerle en contacto con uno para la emisión de un
              dictamen jurídico. El SITIO sólo pone en contacto al USUARIO con
              el experto, pero en ningún caso le aconseja de ninguna manera.
            </p>
            <p>
              {" "}
              En consecuencia, no existe relación contractual entre el SITIO y
              el USUARIO pues su exclusiva labor es la de intermediación entre
              éste y el experto a los efectos de ponerlos en contacto para la
              prestación de los servicios ofertados, de forma que el importe del
              precio que paga el USUARIO (incluidos los gastos de tramitación y
              gestión en caso de anulación o desistimiento) se destina a
              remunerar al experto previo descuento de los importes pactados con
              el SITIO en concepto de intermediación, por lo que la relación
              contractual del USUARIO lo es con el experto, perfeccionándose el
              contrato en el momento en que por el USUARIO se efectúa la compra
              y por el SITIO simultáneamente se abona al experto el importe
              pagado, menos los importes de intermediación pactados, quedando el
              SITIO al margen de la relación contractual entre cliente y su
              abogado, siendo por tanto excluido de la responsabilidad que tras
              la perfección del contrato pudiera surgir debido a las relaciones
              entre estos.
            </p>
            <p>
              En estos términos y condiciones, este servicio de conexión con un
              experto permite al USUARIO obtener el asesoramiento por un período
              de 30 (treinta) minutos.
            </p>
            <p>
              Más allá de los 30 (treinta) minutos asignados, el USUARIO puede
              elegir continuar beneficiándose del asesoramiento del experto
              conforme a las instrucciones que éste le indique.
            </p>
            <p>
              {" "}
              El USUARIO reconoce que cualquier información personal dada por el
              USUARIO al experto es proporcionada voluntariamente, y el SITIO no
              es responsable de la divulgación de dicha información.
            </p>
            <p>
              El SITIO no garantiza la validez y la exactitud de la información
              y/asesoramiento ofrecido por los expertos, ni su trabajo. El SITIO
              no se hace responsable de ningún asesoramiento jurídico o
              información proporcionada por el experto, siendo la
              responsabilidad exclusivamente de éstos frente al USUARIO como
              consta en documento de exoneración al efecto suscritos entre el
              SITIO y los expertos.
            </p>
            <p>
              El USUARIO y los expertos exoneran de responsabilidad al SITIO y a
              negligenciasdebogados.net respecto de reclamaciones por hechos y/o
              daños y perjuicios derivados directa o indirectamente de la
              utilización por el USUARIO de los SERVICIOS del SITIO y de la
              consulta y/o dictamen jurídico contratados a través del SITIO.
            </p>
            <h4> 8.- DERECHO DE DESISTIMIENTO</h4>
            <p>
              {" "}
              El USUARIO no puede hacer valer un derecho de retractación una vez
              que la consulta telefónica o el dictamen ha sido puesto a su
              disposición, excepto en los supuestos de devolución de parte del
              precio contemplados en los apartados correspondientes.
            </p>
            <h4> 9.- POLITICA DE PROTECCION DE DATOS DE CARÁCTER PERSONAL</h4>
            <p> 9.1. DATOS PERSONALES RECOPILADOS</p>
            <p>
              negligenciasdebogados.net recogerá de forma segura los siguientes
              datos personales cuando un USUARIO compre la consulta y/o el
              dictamen:
            </p>
            <ul>
              {" "}
              <li>Un comprobante de compra del servicio seleccionado.</li>
              <li>
                Facturas internas utilizadas por negligenciasdeabogados.net S.C
                con fines fiscales.
              </li>{" "}
              <li>
                Nombre, apellidos, dirección de correo electrónico y datos de
                tarjeta de crédito del USUARIO sólo en el momento del pago de
                los servicios.
              </li>
            </ul>
            <p> 9.2. ALOJAMIENTO WEB</p>
            <p>
              {" "}
              El anfitrión de la web de negligenciasdebogados.net tiene sede en
              España
            </p>
            <p>
              Los datos personales recogidos por negligenciasdeabogados.net se
              alojan y almacenan exclusivamente en España.
            </p>
            <p> 9.3. DATOS PERSONALES TRANSMITIDOS A TERCEROS</p>
            <p>
              {" "}
              Ningún dato personal recogido por negligenciasdeabogados.net se
              comparte con terceros, a excepción de los concretos de cada
              USUARIO a los efectos de ponerles en contacto con los expertos,
              siendo éstos los responsables de su tratamiento y cancelación,
              asegurándose que todos los datos personales de los USUARIOS se
              utilicen explícitamente como se describe en esta política,
              garantizando la mejora continua del tratamiento con el objetivo de
              alcanzar la excelencia en relación con el cumplimiento del
              Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de
              27 de abril de 2016, relativo a la protección de las personas
              físicas en lo que respecta al tratamiento de datos personales y a
              la libre circulación de estos datos y por el que se deroga la
              Directiva 95/46/CE (Reglamento general de protección de datos)
              (DOUE L 119/1, 04-05-2016), y de la normativa española de
              protección de datos de carácter personal (Ley Orgánica,
              legislación sectorial específica y sus normas de desarrollo).
            </p>
            <p>
              negligenciasdeabogados.net no comunicará nunca tus datos
              personales a terceros sin tu consentimiento libre, específico,
              informado e inequívoco con excepción de los supuestos legales
              donde este no sea necesario, por ejemplo, en los casos requeridos
              por ley o cumplimiento con el encargo profesional encomendado.
            </p>
            <p>
              {" "}
              Como ejemplo de lo anterior, negligenciasdeabogados.net podrá
              realizar cesiones o comunicaciones de datos personales para
              atender tus obligaciones con las Administraciones Públicas en los
              casos que así se requiera de acuerdo con la legislación vigente en
              cada momento y en su caso, igualmente a otros órganos como Fuerzas
              y Cuerpos de Seguridad del Estado y a los órganos Judiciales.
            </p>
            <p>
              Los datos personales de los USUARIOS se eliminarán inmediatamente
              tras el pago de los servicios una vez se produzca el asesoramiento
              telefónico o por mensajería instantánea o se termine de abonar el
              segundo pago del dictamen o cuando un USUARIO lo solicite respecto
              de los almacenados comprobantes de compra de servicios y facturas
              internas con fines fiscales, a menos que hacerlo suponga un coste
              o esfuerzo desproporcionado.
            </p>
            <p>
              Si la integridad, confidencialidad o seguridad de los datos
              personales de los USUARIOS se ven comprometidas
              negligenciasdebogados.net informará a todos los USUARIOS.
            </p>
            <p>
              {" "}
              9.4. DERECHO DE LOS USUARIOS EN RELACIÓN A LOS DATOS PERSONALES
              RECOGIDOS
            </p>
            <p>
              {" "}
              No obstante lo anterior, a título enunciativo y según la normativa
              aplicable a la recopilación y el procesamiento de datos, los
              USUARIOS tienen los siguientes derechos:{" "}
            </p>
            <ul>
              <li>
                {" "}
                El derecho a obtener, acceder y/o rectificar los datos
                personales recogidos por negligenciasdeabogados.net.
              </li>
              <li>
                El derecho a la eliminación de los datos personales recogidos
                por negligenciasdebogados.net
              </li>
              <li>
                {" "}
                El derecho a la restricción del procesamiento de datos
                personales por parte de negligenciasdeabogados.net{" "}
              </li>
              <li> El derecho a la portabilidad de los datos personales; </li>
              <li>
                {" "}
                El derecho a oponerse a la toma de decisiones individuales
                automatizadas;{" "}
              </li>
              <li>
                El derecho a decidir el destino de los datos personales
                recogidos después del fallecimiento del USUARIO.
              </li>
            </ul>{" "}
            <p>
              Si hay preguntas sobre los derechos de un USUARIO, ese USUARIO
              puede ponerse en contacto con la Dirección de
              negligenciasdebogados.net, en adelante responsable de los datos,
              utilizando el formulario de contacto de la web quien responderá al
              USUARIO en un plazo razonable, pero en ningún caso después de 30
              días.
            </p>
            <p>
              {" "}
              El USUARIO también puede presentar quejas sobre el tratamiento de
              datos personales ante un tribunal de la jurisdicción competente o
              ante la autoridad de protección de datos correspondiente. Esto
              incluye si el USUARIO cree que se ha infringido algún derecho.
            </p>
            <p>
              {" "}
              <strong>
                Condiciones aplicables al consentimiento de los niños para la
                recogida de datos personales
              </strong>
            </p>
            <p>
              Los menores de 16 años no pueden dar su consentimiento para el
              tratamiento y la recopilación de sus datos personales.
            </p>
            <p>
              {" "}
              negligenciasdebogados.net puede crear controles por cualquier
              medio para asegurarse de que un USUARIO tiene la edad de
              consentimiento correspondiente. MIRACLE también puede crear
              controles para asegurarse de que si un USUARIO es menor de la edad
              de consentimiento correspondiente, se ha obtenido el
              consentimiento de uno de sus padres o de su representante legal.
            </p>
            <h4> 10.- MEDIDAS DE SEGIURIDAD </h4>
            <p>
              negligenciasdeabogados.net se compromete al cumplimiento de su
              obligación de secreto de los datos de carácter personal y de su
              deber de guardarlos, y adoptará las medidas necesarias para evitar
              su alteración, pérdida, tratamiento o acceso no autorizado, de
              acuerdo con lo establecido por normativa aplicable.
            </p>
            <p>
              negligenciasdeabogados.net tiene implantadas las medidas de
              seguridad de índole técnica y organizativas necesarias para
              garantizar la seguridad de tus datos de carácter personal y evitar
              su alteración, pérdida y tratamiento y/o acceso no autorizado,
              habida cuenta del estado de la tecnología, la naturaleza de los
              datos almacenados y los riesgos a que están expuestos, ya
              provengan de la acción humana o del medio físico o natural, de
              acuerdo con lo establecido por la normativa aplicable.
            </p>
            <p>
              negligenciasdeabogados.net entidad preocupada por la privacidad,
              en orden a reforzar la confidencialidad e integridad de la
              información en su organización, mantiene continuamente la
              supervisión, control y evaluación de tus procesos para asegurar el
              respeto a la privacidad y seguridad de la información, según los
              estándares internacionales.
            </p>
            <h4> 11.- POLITICA DE COOKIES </h4>
            <p>
              negligenciasdebogados.net puede usar cookies. Una cookie es un
              pequeño archivo almacenado en el ordenador del USUARIO por MIRACLE
              mientras el USUARIO está usando el SITIO WEB.
            </p>
            <p>
              {" "}
              Las cookies se utilizan para mejorar la experiencia de navegación
              del USUARIO. Las cookies que utiliza negligenciasdeabogados.net
              son "cookies de sesión" y no requieren el consentimiento del
              USUARIO.
            </p>
            <p>
              {" "}
              Las cookies utilizadas por negligenciasdebogados.net se borran una
              vez que el USUARIO cierra el navegador utilizado para acceder al
              SITIO.
            </p>
            <h4> 12.- LINKS</h4>
            <p>
              La página web de negligenciasdeabogados.net puede incluir
              hipervínculos a otros sitios que no son operados o controlados por
              negligenciasdeabogados.net. Por ello, negligenciasdeabogados.net
              no garantiza, ni se hace responsable de la licitud, fiabilidad,
              utilidad, veracidad y actualidad de los contenidos de tales sitios
              web o de tus prácticas de privacidad. Por favor antes de
              proporcionar tu información personal a estos sitios web ajenos a
              negligenciasdeabogados.net, ten en cuenta que su cumplimiento en
              materia de protección de datos puede diferir del nuestro.
            </p>
            <h4> 13.- MODIFICACION DE LA POLITICA DE PRIVACIDAD</h4>
            <p>
              Tenemos derecho a modificar nuestra Política de Privacidad de
              acuerdo con la legislación aplicable en cada momento. En todo
              caso, cualquier modificación de la Política de Privacidad te será
              debidamente notificada para que quedes informado de los cambios
              realizados en el tratamiento de tus datos personales y, en caso de
              que la normativa aplicable así lo exija, puedas otorgar tu
              consentimiento.
            </p>
            <h4> 14.- INDEPENDENCIA DE LAS DISPOSICIONES</h4>
            <p>
              {" "}
              La invalidez de una o más de estas disposiciones no invalida
              ninguna otra disposición de estos Términos y Condiciones. El resto
              de las disposiciones, que siguen siendo válidas, permanecerán en
              pleno vigor y efecto.
            </p>
            <h4> 15.- RENUNCIA</h4>
            <p>
              {" "}
              En caso de que negligenciasdebogados.net no haga cumplir una o más
              de las disposiciones del presente documento, no dará derecho al
              USUARIO a no cumplir esa disposición o cualquier otra de los
              presentes Términos y Condiciones, y no constituirá ni se
              considerará una renuncia definitiva al reconocimiento de los
              derechos de negligenciasdebogados.net
            </p>
            <h4>
              {" "}
              16.- MODIFICACIÓN DE LOS TÉRMINOS Y CONDICIONES GENERALES DE USO Y
              VENTA{" "}
            </h4>
            <p>
              negligenciasdebogados.nettiene el derecho de enmendar
              unilateralmente los Términos y Condiciones y de publicar una
              versión actualizada o editada de los Términos y Condiciones en el
              sitio web. El USUARIO se compromete a leer los Términos y
              Condiciones cada vez que acceda al SITIO.
              negligenciasdebogados.netno tiene la obligación de informar
              específicamente al USUARIO sobre cualquier cambio en los Términos
              y Condiciones. El uso continuado del SITIO WEB por parte del
              USUARIO constituye una aceptación continua de estos Términos y
              Condiciones. Las condiciones aplicables son las que se indican en
              el SITIO cuando el USUARIO utiliza cualquiera de los SERVICIOS del
              SITIO o el propio SITIO.
            </p>
            <h4>
              17.- LEY APLICABLE, IDIOMA Y DISPOSICIÓN DE LA JURISDICCIÓN
              COMPETENTE
            </h4>
            <p>
              negligenciasdebogados.net es una empresa registrada en España y
              realiza operaciones en España solamente.
            </p>
            <p>
              Por la presente, el USUARIO se somete a la jurisdicción Española y
              reconoce plenamente que estos Términos y Condiciones se rigen por
              las leyes españolas (sin tener en cuenta el conflicto de las
              disposiciones legales) y que no se aplicará ninguna otra ley
              extranjera o internacional, incluyendo: (i) la Convención de las
              Naciones Unidas sobre los Contratos de Compraventa Internacional
              de Mercaderías; (ii) la Convención de 1974 sobre la Prescripción
              en Materia de Compraventa Internacional de Mercaderías; y (iii) el
              Protocolo de enmienda de la Convención de 1974, hecho en Viena el
              11 de abril de 1980.
            </p>
            <p>
              El USUARIO reconoce además que cualquier resolución de
              controversias que se requiera, incluyendo el arbitraje, el litigio
              u otro método, con renuncia a su fuero, se resolverá
              exclusivamente ante los Juzgados y Tribunales competentes de
              Málaga.
            </p>
            <p>
              {" "}
              En la medida en que la ley lo permita y especialmente en caso de
              cualquier controversia entre las partes, el idioma de control de
              estos Términos y Condiciones es el español.
            </p>
            <p>
              {" "}
              Sin embargo, en caso de cualquier disputa o reclamación, antes de
              emprender cualquier método que implique a un tercero, el USUARIO
              acepta contactar primero con negligenciasdeabogados.net
              directamente para que las partes puedan llegar a una solución
              amistosa.
            </p>
          </div>
        </Layout>
      </React.Fragment>
    )
    */
}
